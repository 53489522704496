<template>
  <div class="main">
    <div class="wrapper pt-4">
      <div class="surface bg-white shadow d-flex flex-column px-4 pt-5 pb-4 mt-4 w-100 top">
        <h2 class="mb-5 text-center text-sm-left">Reset your Password</h2>
        <form class="d-flex flex-column mt-2" @submit.prevent="submit" novalidate>
          <div class="form-group mt--1">
            <label class="auth-labels text-uppercase small pl-1" for="passwd">Password*</label>
            <div :class="{ error: v$.passwd.$errors.length }">
              <input type="password" class="form-control" name="passwd" v-model="v$.passwd.$model" @input="v$.passwd.$touch" @keyup="formCheck" placeholder="Password123@">
              <div class="input-errors" v-for="error of v$.passwd.$errors" :key="error.$uid">
                <div v-if="error.$uid === 'password-strongPassword'" class="error-msg">Please use at least 1 lower case, 1 uppercase, 1 number and 1 special character.</div>
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="form-group mt-2">
            <label class="auth-labels text-uppercase small pl-1" for="confirmation_passwd">Confirm Password*</label>
            <div :class="{ error: v$.confirmation_passwd.$errors.length }">
              <input type="password" class="form-control" name="confirmation_passwd" v-model="v$.confirmation_passwd.$model" @input="v$.confirmation_passwd.$touch" @keyup="formCheck" placeholder="Password123@">
              <div class="input-errors" v-for="error of v$.confirmation_passwd.$errors" :key="error.$uid">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <button type="submit" class="btn btn-primary ml-auto mt-3" :disabled="disabledButton" @click.prevent="changePassword">Change Password</button>
        </form>
        <div v-if="errors" class="d-flex flex-column pt-4 pb-4 w-100 top">
            <p class="small text-muted text-error" style="margin-bottom: 0">{{ errorsMsm }}</p>
        </div>
      </div>
      <div class="surface d-flex align-items-center justify-content-center mt-3">
        <p class="font-weight-normal mb-0 mr-2">Don't have an account?</p>
        <router-link class="text-primary font-weight-bold ml-2" :to="{ name: 'signup', params: {} }">Sign up</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, sameAs } from '@vuelidate/validators'
import Utils from '../utils/Utils'

export default {
  name: 'reset-password',
  data () {
    return {
      email: '',
      disabledButton: true,
      passwd: '',
      confirmation_passwd: '',
      token: '',
      errors: false,
      errorsMsm: ''
    }
  },
  validations () {
    return {
      passwd: {
        required,
        minLength: minLength(8),
        strongPassword (passwd) {
          return (/[a-z]/.test(passwd) && /[A-Z]/.test(passwd) && /[0-9]/.test(passwd) && /\W|_/.test(passwd))
        }
      },
      confirmation_passwd: { required, sameAsPassword: sameAs(this.passwd) }
    }
  },
  setup: () => ({ v$: useVuelidate() }),
  validationConfig: { $autoDirty: true },
  methods: {
    formCheck () {
      var formErrors = document.getElementsByClassName('error-msg')
      if (formErrors.length > 0 || this.passwd === '' || this.confirmation_passwd === '') {
        this.disabledButton = true
      } else {
        this.disabledButton = false
      }
    },
    changePassword (evt) {
      var data = new FormData()
      data.append('pword', this.passwd)
      data.append('pword_confirmation', this.confirmation_passwd)
      data.append('token', this.token)
      this.axios
        .post(process.env.VUE_APP_API + '/reset', data)
        .then((response) => {
          if (response?.status === 201 || response?.status === 200) {
            this.$router.push({ path: '/password-updated', params: {} })
          }
        })
        .catch((err) => {
          const { response } = err
          if (response?.data?.message) {
            this.errors = true
            this.errorsMsm = response.data.message
            setTimeout(() => {
              this.errors = false
            }, 3000)
          }
          Utils.$handleHttpErrors(err)
        })
    }
  },
  mounted () {
    this.token = this.$route.query.token
  }
}
</script>

<style lang="scss" scoped>
  .text-error {
    color: #ff0000 !important;
    opacity: 0.6;
  }
</style>
